@import '~antd/dist/reset.css';

html,
body,
#root {
  height: 100dvh;
  min-height: 100dvh;
  font-family: "Poppins", sans-serif;
  scrollbar-width: 3px !important;
  scrollbar-color: #2ECD99 !important;
  scroll-behavior: smooth;
  background-color: #fff !important;
  background: #fff !important;
  user-select: none;
}

html::-webkit-scrollbar,
body::-webkit-scrollbar {
  width: 0;
  display: none !important;
}

a {
  text-decoration: none;
}

.ant-table {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

.ant-table-wrapper .ant-table-thead th.ant-table-column-sort,
.ant-table-wrapper td.ant-table-column-sort {
  background-color: transparent;
}

.ant-table-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.ant-table-body {
  scrollbar-width: 0;
  scrollbar-color: auto;
}

.ant-table-tbody-virtual-scrollbar,
.ant-table-body::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 18px !important;
  background: transparent !important;
  border: none !important;
  box-shadow: none !important;
  cursor: pointer;
}

.ant-table-body::-webkit-scrollbar-corner,
::-webkit-scrollbar-corner {
  background: transparent !important;
}

.ant-table-tbody-virtual-scrollbar-thumb,
.ant-table>.ant-table-container>.ant-table-body::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  cursor: pointer;
  border: 7px solid transparent !important;
  border-radius: 999px !important;
  background-clip: padding-box !important;
  background-color: #2ECD99 !important;
}

.ant-table-tbody-virtual-scrollbar-thumb,
.ant-table>.ant-table-container>.ant-table-body::-webkit-scrollbar-button,
::-webkit-scrollbar-button {
  height: 0 !important;
  width: 0 !important;
  background: transparent !important;
  opacity: 0 !important;
  display: none !important;
}

.mobile-datepicker-dropdown {
  min-width: auto;
  max-width: 100%;
  width: max-content;
  z-index: 1000;
}

.segment-label>.ant-segmented-item-label {
  text-overflow: initial;
}

.ant-segmented-item {
  min-width: min-content !important;
}

.ant-table-column-sorters {
  gap: 5px;
  justify-content: start !important;
}

.ant-table-column-title,
.ant-table-cell {
  flex: 1 1 !important;
  max-width: max-content;
  user-select: none !important;
}

.ant-segmented-group {
  gap: 4px;
}

.ant-slider.ant-slider-disabled .ant-slider-dot {
  display: none !important;
}

.ant-table-container {
  gap: 8px;
}

.ant-input-group-addon {
  width: 48px !important;
  font-size: 12px !important;
}

.ant-slider-mark-text:last-of-type[style^="left"] {
  transform: translateX(-70%) !important;
}

.ant-slider-mark-text:last-of-type[style^="right"] {
  transform: translateX(70%) !important;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.ant-table-container::after,
.ant-table-container::before {
  box-shadow: none !important;
}

.widget-wrapper>.widget-fab-shadow::before {
  background: none !important;
  box-shadow: none !important;
}

.tradingview-widget-container {
  /* height: auto !important; */
}

.ant-segmented.custom>.ant-segmented-group>.ant-segmented-item.ant-segmented-item-disabled {
  background-color: #9E9E9E;
  color: #fff;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector,
.ant-select-multiple .ant-select-selector {
  padding: 4px 11px;
}

.ant-segmented.ant-segmented-lg .ant-segmented-item-label {
  font-size: 15px;
}

.ant-table-wrapper .ant-table.ant-table-small,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-cell>span,
.ant-table-wrapper .ant-table.ant-table-small .ant-table-cell>div>span {
  font-size: 12px !important;
}